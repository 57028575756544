<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-plus" @click="addPage()">添加新卡</vxe-button>
      </template>
    </vxe-toolbar>
    <div class="search" v-if="seachStatus">
      <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
        <vxe-form-item title="老人姓名" span="8">
          <vxe-input v-model="searchData.pubOldPersonName" placeholder="请输入老人姓名" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="联系电话" span="8">
          <vxe-input v-model="searchData.tel" placeholder="请输入联系电话" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="逻辑卡号" span="8">
          <vxe-input v-model="searchData.logicalCardNo" placeholder="请输入逻辑卡号" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="物理卡号" span="8">
          <vxe-input v-model="searchData.physicalCardNo" placeholder="请输入物理卡号" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:operation="{ row }">
          <vxe-button type="text" status="primary" @click="goPage(row)" v-if="row.cardStatus=='正常'">充值</vxe-button>
          <vxe-button type="text" status="primary" @click="goTransList(row)">交易流水</vxe-button>
          <vxe-button type="text" status="primary" @click="destoryCard(row)"  v-if="row.cardStatus=='正常'">退卡</vxe-button>
          <vxe-button type="text" status="primary" @click="deletTrant(row)" style="color:red" v-if="row.cardStatus=='已退款'">删除</vxe-button>

        </template>
      </vxe-grid>
    </div>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      ></vxe-pager>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      seachStatus: false,
      searchData: {},
      dialogFormVisible: false,
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 80,
          align: 'center',
        },
        {
          field: 'pubOldPersonName',
          title: '老人姓名',
          showOverflow: true,
          align: 'center',
          minWidth: '80',
        },
        {
          field: 'pubOldPersonIdNum',
          title: '身份证号',
          showOverflow: true,
          align: 'center',
          minWidth: '100',
        },
        {
          field: 'tel',
          title: '联系电话',
          showOverflow: true,
          align: 'center',
          minWidth: '100',
        },
        {
          field: 'logicalCardNo',
          title: '逻辑卡号',
          showOverflow: true,
          align: 'center',
          minWidth: '100',
        },
        {
          field: 'physicalCardNo',
          title: '物理卡号',
          showOverflow: true,
          align: 'center',
          minWidth: '100',
        },
        {
          field: 'balance',
          title: '余额',
          showOverflow: true,
          align: 'center',
          minWidth: '60',
        },
        {
          field: 'cardStatus',
          title: '状态',
          showOverflow: true,
          align: 'center',
          minWidth: '60',
        },
        {
          title: '操作',
          align: 'center',
          minWidth: '180',
          showOverflow: true,
          slots: {
            default: 'operation',
          },
        },
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId']),
  },
  watch: {
    seqId() {
      // this.getLists()
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['getMealCardList', 'addMealCard', 'searchPerson', 'destoryCard', 'addTransCard', 'cardTransList','deleteTrans']),
    initView() {
      // this.getTeamList();
      // this.getCategoryList();
      this.getLists()
    },

    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    //开卡
    addPage(){
      this.$router.push({
        name: 'addCard',
      })
    },
    //充值
    goPage(itemData) {
      this.$router.push({
        name: 'addTrans',
        params: {
          id: itemData.id,
        },
        query: {
          item: JSON.stringify(itemData),
        },
      })
    },
    //充值流水
    goTransList(row) {
      console.log(row.id)
      this.$router.push({
        name: 'rechangeList',
        params: {
          id: row.id,
        },
      })
    },
    //退卡
    destoryCard(itemData) {
      this.$router.push({
        name: 'destoryCard',
        params: {
          id: itemData.id,
        },
        query: {
          item: JSON.stringify(itemData),
        },
      })
    },
    //删除
    deletTrant(row){
      console.log(row);
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.deleteTransM(row.id)
        }
      })
    },
    deleteTransM(id){
       this.deleteTrans({
        id,
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success',
          })
          this.getLists()
        }
        this.loading = false
      })
    },
    getLists() {
      this.loading = true
      this.getMealCardList({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          ...this.searchData,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records.map((item) => {
            return {
              ...item,
              cardStatus: item.cardStatus == 1 ? '正常' : '已退款',
            }
          })
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      console.log(this.searchData)
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.delmealOrg({
        id,
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success',
          })
          this.getLists()
        }
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
